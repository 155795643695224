import React, { FC } from 'react'
import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import { WidthContainer } from 'components/layout'
import { useDevice } from 'device'
import { NextPage } from 'next'
import s from './404Page.module.scss'
import cx from 'classnames'


const C404: FC = () => {
  return (
    <div className={cx(s.img)}>
      <img className={s.ff} src="/images/graphics/404.svg" alt="404" />
      <img className={s.o} src="/images/graphics/0.webp" alt="404" />
    </div>
  )
}

const Page404: NextPage = () => {
  const { isMobile } = useDevice()

  return (
    <WidthContainer className={cx('flex flex-col items-center justify-center', s.container)}>
      <C404 />
      <Text className="mt-32 text-center" style="sub" message="The requested URL could not be found on the server" />
      <div className={cx('mt-32 flex justify-center', isMobile && 'flex-col w-full')}>
        <Button
          size={40}
          title="Go to home page"
          to="/"
          fullWidthOnMobile
        />
        <Button
          className={cx(isMobile ? 'mt-12' : 'ml-12')}
          style="stroked"
          size={40}
          title="explore editions"
          to="/curated-gallery"
          fullWidthOnMobile
        />
      </div>
    </WidthContainer>
  )
}

export default Page404
